import { ReactNode } from "react";

type LoadingProps = {
  isLoading?: boolean;
  children?: ReactNode | ReactNode[];
};
export function Loading(props: LoadingProps) {
  const isLoading = props.isLoading ?? false;
  if (isLoading) {
    return (
      <div className="panel-loader d-flex align-items-center">
        <span className="spinner spinner-sm"></span> {props.children}
      </div>
    );
  }
  return <></>;
}