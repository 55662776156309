import { Link, generatePath } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import useLabel from "../../util/useLabel";
import { ReactNode, useEffect, useState } from "react";
import Attribute from "../../models/attribute";
import { AttributeValueSummary } from "../attribute/attributeValueSummary";
import { RecordType } from "../../models/record";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchAttributes, selectConfig } from "../../store/configSlice";
import AttributeValue from "../../models/attributeValue";
import { conditionMatch, hasValue } from "../../models/attributeCondition";
import { RequiresRole } from "../role/requiresRole";
import Roles from "../../models/roles";


interface RecordsDisplayAttribute {
  label: string;
  hide?: boolean;
  headerClassNames?: string;
  callback: (record: RecordType) => ReactNode | ReactNode[];
}
export interface ProjectDetailsCardParams {
  type: EntityTypes;
  record: RecordType;
  attributeValues?: AttributeValue[];
  editRoute?: string;
  detailsRoute?: string;
  displayAttributes?: RecordsDisplayAttribute[];
  colClassName?: string;
  footer?: ReactNode | ReactNode[];
  //children?(footer: ): React.ReactElement<HTMLElement>;
  //children(provided: DroppableProvided, snapshot: DroppableStateSnapshot): React.ReactElement<HTMLElement>;
}

export default function ProjectDetailsCard(props: ProjectDetailsCardParams) {
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[props.type];
  useEffect(() => { dispatch(fetchAttributes(props.type)) }, [dispatch, props.type]);

  const entityType = props.type;
  const label = useLabel(entityType);
  const { editRoute, record, displayAttributes, detailsRoute, footer } = props;
  const [hideAttributeIds, setHideAttributeIds] = useState<number[]>([]);

  useEffect(() => {
    // Process conditional fields
    if (attributes) {
      const attributeValues: AttributeValue[] = [
        ...record?.attributes ?? [],
        ...props.attributeValues ?? [],
      ];

      setHideAttributeIds(attributes.filter((attr) => {
        let show = (attr.visibleDefault ?? true);
        if (attr.hideIf) {
          console.log('hideIf', attr);
          if (attr.hideIf.some((cond) => conditionMatch(cond, attributeValues))) {
            show = false;
          }
        }
        if (!show && attr.showIf) {

          if (attr.showIf.some((cond) => conditionMatch(cond, attributeValues))) {
            show = true;
          }
          // console.log('showIf', attr, show);
        }
        if (!show && hasValue(attr, attributeValues)) {
          // Show attributes that have a value, but are supposed to be hidden (e.g. they were migrated with a value)
          show = true;
        }
        return !show;
      }).map((attr) => attr.id!));
    }
  }, [attributes, record, props.attributeValues]);


  return (<div className="card border-0 mb-4">
    <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
      {label.singular}
      {editRoute && <RequiresRole role={Roles.Analyst}><Link to={generatePath(editRoute, { id: `${record?.id}` })} className="ms-auto text-decoration-none text-gray-500"><i className="fa fa-edit fa-lg me-2 ms-n2"></i> Edit</Link></RequiresRole>}
      {detailsRoute && <Link to={generatePath(detailsRoute, { id: `${record?.id}` })} className="ms-auto text-decoration-none text-gray-500"><i className="fa fa-external-link fa-lg me-2 ms-n2"></i> Details</Link>}
    </div>
    <div className="card-body fw-bold row">
      {record?.name && <div className={props.colClassName ?? "col-12"}>
        <h6 className="card-subtitle text-muted">{label.singular} Name</h6>
        <p>
          {record?.name}
        </p>
      </div>}
      {displayAttributes && displayAttributes.map((display) => (display.hide ?? false) ? null : <div className={props.colClassName ?? "col-12"} key={display.label}>
        <h6 className="card-subtitle text-muted">{display.label}</h6>
        <div className="mb-3">
          {display.callback(record)}
        </div>
      </div>)}
      {/* <h6 className="card-subtitle text-muted">Active?</h6>
      <p className="card-text">
        {record?.isActive ? "Yes" : "No"}
      </p> */}
      {attributes
        ?.filter((attribute: Attribute) => !hideAttributeIds.includes(attribute.id!))
        .map((attribute: Attribute) => {
        const attributeValue = record?.attributes?.find((t) => t?.id === attribute.id);
        if (attributeValue) {
          return <div className={props.colClassName ?? "col-12"} key={`attribute-${attribute.id}`}>
            <h6 className="card-subtitle text-muted">{attribute.name}</h6>
            <div className="mb-3"><AttributeValueSummary attribute={attribute} attributeValues={record?.attributes} /></div>
          </div>;
        }
        return <div key={`attribute-${attribute.id}`}></div>; // no value
      })}
    </div>
    {footer && <div className="card-footer">{footer}</div>}
  </div>);
}