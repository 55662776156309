import { generatePath, useNavigate } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Project from "../../models/project";
import { ProjectDetailsRoute } from "./projectDetailsScreen";
import { ProjectEditRoute } from "./projectEditScreen";
import RecordsListScreen from "../recordsListScreen";
import useLabel from "../../util/useLabel";
import KnowledgeMapTypes from "../../models/knowledgeMapTypes";

export const ProjectsRoute = "/projects";

export default function ProjectsScreen() {
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);
  const projectLabel = useLabel(EntityTypes.Project);

  const filters = [
    { key: 'all', label: `All ${projectLabel.plural}`, api: api.projects.search },
    { key: 'my', label: `My ${projectLabel.plural}`, api: api.projects.searchMine },
  ];

  return <RecordsListScreen
    type={EntityTypes.Project}
    filters={filters}
    detailsRoute={ProjectDetailsRoute}
    columns={[
      {
        label: 'Partner',
        beforeAttributes: true,
        callback: (record: Project) => <td>{record.partner?.name}</td>
      },
      {
        label: 'Type',
        //headerClassNames: 'text-end',
        callback: (record: Project) => <td>{labels.knowledgeMapTypes[record.knowledgeMapType ?? KnowledgeMapTypes.Undefined]}</td>
      },
    ]}
    actions={(record) => <>
      <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(ProjectDetailsRoute, { id: `${record.id}` }))}>Details</button>
      <button className="btn btn-sm btn-outline-secondary mx-1" onClick={() => navigate(generatePath(ProjectEditRoute, { id: `${record.id}` }))}>Edit</button>
    </>}
  />;


}