import { SearchProps, api } from "../../store/api";
import EntityTypes from "../../models/entityTypes";
import RecordsList, { RecordFilter } from "../records/recordsList";
import ProjectResource, { ProjectResourceStatus } from "../../models/projectResource";
import { ProjectResourceEditRoute } from "../../screens/projectResources/editScreen";
import { generatePath, useNavigate } from "react-router-dom";
import { selectLabels } from "../../store/labelsSlice";
import { useAppSelector } from "../../store/hooks";
import { RequiresPermission } from "../role/requiresRole";
import { PermissionTypes } from "../../models/permissionTypes";
import { ResourceDetailsRoute } from "../../screens/resources/resourceDetailsScreen";
import GradeTypes from "../../models/gradeTypes";

type ProjectResourceListProps = {
  projectId: number;
  grades: GradeTypes[];
}


export function ProjectResourceList(props: ProjectResourceListProps) {
  const { grades } = props;
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);

  return <RecordsList
    type={EntityTypes.ProjectResource}
    filters={[
      { key: 'all', label: 'All', api: (search: SearchProps) => api.projectResources.forProject({ ...search, projectId: props.projectId }) },
      { key: 'needs-review', label: 'Needs Review', api: (search: SearchProps) => api.projectResources.forProject({ ...search, projectId: props.projectId, status: ProjectResourceStatus.NeedsReview }) },
      ...grades.map<RecordFilter<ProjectResource>>((val, idx, arr) => Object.assign({
        key: `grade-${val}`,
        label: 'Grade ' + labels.grades[val],
        api: (search: SearchProps) => api.projectResources.forProject({ ...search, projectId: props.projectId, grade: val })
      }))
    ]}
    columns={[
      {
        label: "Grade",
        sortKey: 'grade',
        headerClassNames: 'text-end',
        callback: (record: ProjectResource) => <td className="text-end">{labels.grades[record.gradeUnit?.grade ?? GradeTypes.NA]}</td>
      },
      {
        label: "Unit",
        sortKey: 'unit',
        headerClassNames: 'text-end',
        callback: (record: ProjectResource) => <td className="text-end">{record?.gradeUnit?.unit}</td>
      },
      {
        label: "Is Anchor?",
        headerClassNames: 'text-end',
        callback: (record: ProjectResource) => <td className="text-end">{record?.isAnchor && <i className="fa-solid fa-anchor me-1"></i>}{record?.isAnchor ? 'Yes' : 'No'}</td>
      },
      {
        label: labels.review.plural,
        headerClassNames: 'text-end',
        sortKey: 'reviews',
        callback: (record: ProjectResource) => <td className="text-end">{record?.reviews}</td>
      }
    ]}
    actions={(record: ProjectResource) => <>
      <button className="btn btn-sm btn-outline-secondary mx-1 py-1" onClick={() => navigate(generatePath(ResourceDetailsRoute, { id: `${record.resourceId}` }))}>View Resource</button>
      <RequiresPermission permission={PermissionTypes.ProjectResourcesEdit}>
        <button className="btn btn-sm btn-outline-secondary mx-1 py-1" onClick={() => navigate(generatePath(ProjectResourceEditRoute, { projectId: `${record.project?.id}`, id: `${record.id}` }))}>Edit</button>
      </RequiresPermission>
    </>}
  />;
}