import { ReactNode } from "react";
import Tag from "../../models/tag";
import { Link, generatePath } from "react-router-dom";
import { TagDetailsRoute } from "../../screens/settings/tagScreen";

export default function tagBreadcrumb(record: Tag | undefined): ReactNode {
  //{record?.parentTag && <li className="breadcrumb-item"><Link to={generatePath(TagDetailsRoute, { id: `${record.parentTagId}` })}>{record.parentTag.label}</Link></li>}
  return <>{record?.parentTag && tagBreadcrumb(record.parentTag)}
    <li className="breadcrumb-item active"><Link to={generatePath(TagDetailsRoute, { id: `${record?.id}` })}>{record?.label}</Link></li>
  </>;
}