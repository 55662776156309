import { generatePath, useNavigate } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import RecordsListScreen from "../recordsListScreen";
import { ResourceDetailsRoute } from "./resourceDetailsScreen";
import useLabel from "../../util/useLabel";

export const ResourcesRoute = "/resources";

export default function ResourcesScreen() {
  const navigate = useNavigate();
  const label = useLabel(EntityTypes.Resource);

  return <RecordsListScreen
    type={EntityTypes.Resource}
    detailsRoute={ResourceDetailsRoute}
    filters={[
      { key: 'all', label: `All ${label.plural}`, api: api.resources.search },
      { key: 'mine', label: `My ${label.plural}`, api: api.resources.searchMine },
    ]}
    actions={(record) => <>
      <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(ResourceDetailsRoute, { id: `${record.id}` }))}>Details</button>
    </>}
    />;
}