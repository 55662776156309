import Attribute from "./attribute";
import AttributeTypes from "./attributeTypes";
import AttributeValue from "./attributeValue";
import EntityTypes from "./entityTypes";

export default interface AttributeCondition {
	relatedEntity?: EntityTypes;
	attributeId?: number;
	value?: AttributeValue;
}

// export type ConditionAttributeValues = {
// 	[attributeId: number]: AttributeValue;
// };
export const conditionMatch = (condition: AttributeCondition, attributeValues: AttributeValue[]): boolean => {
	// console.log('conditionMatch', condition, attributeValues);
	const attributeValue = attributeValues.find((av) => av.id === condition.attributeId);
	if (attributeValue) {
		if (condition.value?.choicesValue) {
			// console.warn('conditionMatch choicesValue', condition.value.choicesValue, attributeValue.choicesValue);
			return condition.value?.choicesValue.some((choice) => attributeValue.choicesValue?.includes(choice) ?? false);
			//return attributeValue.choicesValue?.some((choiceValue) => condition.value!.choicesValue!.includes(choiceValue)) ?? false;
		}
	}
	return false;
};

export const hasValue = (attribute: Attribute, attributeValues: AttributeValue[]): boolean => {
	// console.log('conditionMatch', condition, attributeValues);
	const attributeValue = attributeValues.find((av) => av.id === attribute.id);
	if (attributeValue) {
		switch (attribute.type) {
			case AttributeTypes.String:
				return (attributeValue.stringValue !== undefined && attributeValue.stringValue !== null && attributeValue.stringValue !== "");
			case AttributeTypes.NumberWhole:
				return (attributeValue.intValue !== undefined && attributeValue.intValue !== null);
			case AttributeTypes.NumberDecimal:
				return (attributeValue.doubleValue !== undefined && attributeValue.doubleValue !== null);
			case AttributeTypes.Date:
				return (attributeValue.dateValue !== undefined && attributeValue.dateValue !== null);
			case AttributeTypes.YesNo:
				return (attributeValue.boolValue !== undefined && attributeValue.boolValue !== null);
			case AttributeTypes.MultipleChoice:
			case AttributeTypes.Choice:
				if (Array.isArray(attributeValue?.choicesValue) && attributeValue.choicesValue.length > 0) {
					return true;
				}
				else if (typeof attributeValue.choicesValue === "number") {
					return !Number.isNaN(Number(attributeValue.choicesValue));
				}
				else {
					// choice elements from react-form end up as a single number instead of an array of choice values
					//console.log(typeof attributeValue.choicesValue);
					console.warn(`Attribute Value for Attribute ${attribute.id}/${attribute.name} is a ${typeof attributeValue.choicesValue} but should be a choice`);
					return (attributeValue.choicesValue !== undefined && attributeValue.choicesValue !== null) ;
				}
			case AttributeTypes.Contact:
			case AttributeTypes.MultipleContacts:
				return (attributeValue.referencesValue !== undefined && attributeValue.referencesValue !== null && attributeValue.referencesValue.length > 0);
		}
	}
	return false;
};