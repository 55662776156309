import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Partner from "../../models/partner";
import Project from "../../models/project";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { api } from "../../store/api";
import { useEffect, useRef, useState } from "react";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import EntityTypes from "../../models/entityTypes";
import ProjectGrade from "../../models/projectGrade";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import * as Highcharts from 'highcharts';
import highchartsHeatmap from "highcharts/modules/heatmap";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsDrilldown from "highcharts/modules/drilldown";
import HighchartsReact from "highcharts-react-official";
import Tag from "../../models/tag";
import GradeTypes from "../../models/gradeTypes";
import { setTitle } from "../../util/useDocumentTitle";
import { useSearchParams } from "react-router-dom";

export const HeatmapReportRoute = "/reports/heatmap";

export default function HeatmapReportScreen() {
  const labels = useAppSelector(selectLabels);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectIdStr = searchParams.get('project');


  // highchartsTreemap(Highcharts);
  // highchartsTreegraph(Highcharts);
  highchartsExporting(Highcharts);
  highchartsDrilldown(Highcharts);

  const [isLoading, setIsLoading] = useState(false);
  const [partner, setPartner] = useState<Partner>();
  const [partners, setPartners] = useState<Partner[] | undefined>([]);
  const [project, setProject] = useState<Project>();
  const [projects, setProjects] = useState<Project[] | undefined>([]);
  const [domains, setDomains] = useState<Tag[]>();
  // const [grade, setGrade] = useState<ProjectGrade>();
  const [grades, setGrades] = useState<ProjectGrade[]>([]);

  const [error, setError] = useState<String>();

  useEffect(() => {
    if (projectIdStr) {
      api.projects.get(parseInt(projectIdStr)).then((project) => {
        setPartner(project.partner);
        setProject(project);
      });
    }
  }, [projectIdStr]);
  
  useEffect(() => {
    if (partner) {
      api.projects.forPartner(partner.id!).then(setProjects);
    }
    else {
      setPartners([]);
      setProject(undefined);
    }
  }, [partner]);

  useEffect(() => {
    if (project) {
      api.project(project.id!).grades().then(setGrades);
    }
    else {
      setGrades([]);
    }
  }, [project]);

  const onGenerateReport = (): React.MouseEventHandler | undefined => {
    setIsLoading(true);
    if (project) {
      api.reports.heatmap(project!.id!).domains().then((domains) => {
        setDomains(domains);
        setIsLoading(false);
      });
    }
    else {
      setDomains(undefined);
    }
    return undefined;
  };


  // Title
  useEffect(() => {
    setTitle([
      project ? project.name : '',
      `Heatmap Report`,
    ]);
  }, [project]);


  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">Heatmap Reporting</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">Reports</li>
          <li className="breadcrumb-item active">Unit Report</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-3">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
            Report Parameters
          </div>
          <div className="card-body">
            <div className="row mb-15px">
              <label className="form-label col-form-label col-md-3"><EntityTypeLabel entityType={EntityTypes.Partner} singlular /></label>
              <div className="col-md-9">
                <AsyncTypeahead
                  options={partners ?? []}
                  id="partnerDropDown"
                  labelKey={"name"}
                  filterBy={['name']}
                  selected={partner ? [partner] : undefined}
                  minLength={0}
                  isLoading={partners === undefined}
                  clearButton={true}
                  onChange={(selected) => {
                    console.warn('onChange', selected);
                    const selectedPartners = selected as Partner[];
                    if (selectedPartners.length > 0) {
                      setPartner(selectedPartners[0]);
                    }
                    else {
                      setPartner(undefined);
                    }
                  }}
                  onSearch={function (search: string): void {
                    setPartners(undefined);
                    api.partners.search({ search }).then((partners) => {
                      setPartners(partners.records);
                    })
                      .catch((reason) => {
                        throw new Error("Error searching for " + labels.partner.plural + ": " + reason);
                      });
                  }}
                />
              </div>
            </div>
            <div className="row mb-15px">
              <label className="form-label col-form-label col-md-3"><EntityTypeLabel entityType={EntityTypes.Project} singlular /></label>
              <div className="col-md-9">
                {partner ? <Typeahead
                  options={projects ?? []}
                  disabled={partner === undefined}
                  id="projectDropDown"
                  labelKey={"name"}
                  filterBy={['name']}
                  selected={project ? [project] : undefined}
                  minLength={0}
                  isLoading={projects === undefined}
                  clearButton={true}
                  onChange={(selected) => {
                    console.warn('onChange', selected);
                    const selectedProjects = selected as Project[];
                    if (selectedProjects.length > 0) {
                      setProject(selectedProjects[0]);
                    }
                    else {
                      setProject(undefined);
                    }
                  }}
                /> : <input disabled className="form-control" />}
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-sm btn-success" disabled={!(partner && project)} onClick={onGenerateReport}>Generate Report</button>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <Panel isLoading={isLoading}>
          <PanelHeader>Heatmap Report</PanelHeader>
          <PanelBody>
            {domains?.map((domain) => <div key={`heatmap-${domain.id?.toString()}`} className="row" id={domain.id?.toString()}>
              {domain && project && <HeatmapForDomain domain={domain} project={project} grades={grades.map(g => g.grade)} />}
            </div>)}
          </PanelBody>
        </Panel>
      </div>
    </div>
  </div>);
}

type HeatmapForDomainProps = {
  domain: Tag;
  project: Project;
  grades: GradeTypes[];
};
function HeatmapForDomain(props: HeatmapForDomainProps) {
  const labels = useAppSelector(selectLabels);
  const { domain, project, grades } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isHidden, setIsHidden] = useState(false);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  highchartsHeatmap(Highcharts);

  useEffect(() => {
    if (domain && project) {
      api.reports.heatmap(project.id!).heatmap(domain).then((heatmap) => {
        setChartOptions({
          title: {
            text: domain.label,
          },
          subtitle: {
            text: 'Count of resources by topic by grade',
          },
          colors: ['red', 'black', 'green', 'blue', 'purple', 'brown'],
          xAxis: {
            opposite: true,
            title: {
              text: 'Grade',
            },
            categories: grades.map((grade) => labels.grades[grade])
          },
          yAxis: {
            categories: heatmap.topics.map(t => t.label!),
            //title: null,
            title: {
              text: 'Topic',
            },
            reversed: true,
          },
          // accessibility: {
          //   point: {
          //     descriptionFormat: '{(add index 1)}. ' +
          //       '{series.xAxis.categories.(x)} sales ' +
          //       '{series.yAxis.categories.(y)}, {value}.'
          //   }
          // },
          colorAxis: {
            min: 0,
            max: 5,
            minColor: '#FFFFFF',
            maxColor: Highcharts.getOptions().colors![0]
          },
          legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280
          },
          tooltip: {
            format: '<b>Grade</b> {series.xAxis.categories.(point.x)}<br>' +
              '<b>{series.yAxis.categories.(point.y)}</b><br />' +
              '{point.description}'
          },
          series: [{
            type: 'heatmap',
            allAreas: true,
            allowPointSelect: true,
            name: 'Resources Tagged',
            borderWidth: 1,
            data: grades.map((grade, x) => {
              return heatmap.topics.map((topic, y) => {
                const resourceCount = heatmap.resourceCounts.find((rc) => rc.grade === grade && rc.topicId === topic.id);
                return {
                  x,
                  y,
                  value: resourceCount?.resources.length ?? 0,
                  //drilldown: `${x}-${y}`,
                  description: resourceCount?.resources.map(r => r.name).join("<br /> "),
                };
              });
            }).reduce((a, b) => a.concat(b), []),
            dataLabels: {
              enabled: true,
              color: '#000000'
            }
          }],
          drilldown: {
            series: grades.map((grade, x) => {
              return heatmap.topics.map((topic, y) => {
                const resourceCount = heatmap.resourceCounts.find((rc) => rc.grade === grade && rc.topicId === topic.id);
                return {
                  id: `${x}-${y}`,
                  type: 'heatmap',
                  name: `Grade ${labels.grades[grade]}`,
                  // type: 'heatmap',
                  data: resourceCount?.resources.map((rc, rcX) => {
                    console.log('heatmap drilldown', rc);
                    return {
                      name: rc.name,
                      x: rcX,
                      y: 0,
                      value: 1,
                    };
                  }),
                } as Highcharts.SeriesHeatmapOptions;
              });
            }).reduce((a, b) => a.concat(b), []),
          },
        });

        setIsLoading(false);
      });

    }
    else {
      setChartOptions(undefined);
    }
  }, [domain, grades, labels.grades, project]);

  return <>
    {/* <h3>{domain.label}</h3> */}
    {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Loading Heatmap of {domain.label}...</p>}
    {/* {isHidden && <button className="btn bt-link no-print">Show Heatmap of {domain.label}</button>} */}
    {!isHidden && chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
    {/* {!isHidden && chartOptions && <button className="btn bt-link">Hide Heatmap of {domain.label}</button>} */}
  </>;
}