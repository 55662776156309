import { Typeahead } from "react-bootstrap-typeahead";
import GradeUnit from "../../models/gradeUnit";
import { useCallback, useEffect, useState } from "react";
import Project from "../../models/project";
import GradeTypes from "../../models/gradeTypes";
import { api } from "../../store/api";

type SelectGradeUnitProps = {
  project: Project;
  grade: number;
  defaultUnit?: string;
  selected?: GradeUnit;
  onSelect: (selected: GradeUnit | undefined) => void;
}
export default function SelectGradeUnit(props: SelectGradeUnitProps) {

  // const [isLoading, setIsLoading] = useState(false);
  // const [grade] = useState<GradeTypes>();
  const [options, setOptions] = useState<GradeUnit[]>([]);
  const [selected, setSelected] = useState<GradeUnit | undefined>(props.selected);
  const [defaultUnit, setDefaultUnit] = useState<string | undefined>(props.selected?.unit);
  //const [valueOptions, setValueOptions] = useState<GradeUnit[]>();

  // const onSelect = (gradeUnit: GradeUnit | undefined) => {
  //   if (gradeUnit?.id !== props.selected?.id) {
  //     console.warn('onSelect gradeUnit', gradeUnit, props.selected);
  //     props.onSelect(gradeUnit);
  //   }
  // };

  const createGradeUnit = (request: GradeUnit) => {
    // is loading?
    api.project(props.project.id!).grade(props.grade).unit(request.unit).create().then((gradeUnit) => {
      console.warn('setting selected', gradeUnit);
      // setSelected(gradeUnit);
      // setDefaultUnit(gradeUnit.unit);
      setSelected(gradeUnit);
      setOptions([gradeUnit, ...options]);
    });
    // TODO handle error
  };

  // Load typeahead-choice options for value selector
  useEffect(() => {
    console.warn('grade change?', props.grade, props.grade?.valueOf());
    // load GradeUnits for Project
    // if (props.grade) {
    //   if (grade?.valueOf() !== props.grade?.valueOf()) {
    //     //setSelected(undefined);
    //     onSelect(undefined);
    //   }
    // }
    setOptions([]);
    if (props.project.id) {
      api.project(props.project.id).grade(props.grade).units().then((units) => {
        setOptions(units);
        console.warn('GRADE change setting units', units);
        if (defaultUnit) {
          const newlySelected = units.find((e) => e.unit === defaultUnit);
          setSelected(newlySelected);
        }
        else {
          console.log('Grade changed, but no selected unit is set.');
        }
        // if (props.selected?.unit) {
        //   debugger;
        //   setSelected(newlySelected);
        // }
      });
    }
  }, [props.project, props.grade, defaultUnit]);

  useEffect(() => {
    console.log('selected changed', selected, props.selected);
    if (props.onSelect && props.selected !== selected) {
      console.log('onSelect', selected);
      props.onSelect(selected);
    }
  }, [props, selected]);

  return <div className="d-flex flex-row align-items-center">
    <Typeahead
      id="selectGradeUnit"
      //id={`choices.${props.index}.dependantOnAttributeChoiceIds`}
      options={options}
      labelKey="unit"
      allowNew={true}
      newSelectionPrefix="Create new unit: "
      clearButton={true}
      emptyLabel={'Select...'}
      multiple={false}
      selected={selected !== undefined ? [selected] : []}
      isLoading={false}
      onChange={(selected) => {
        const selectedGradeUnits = selected as GradeUnit[];
        // console.log('onChange', selectedGradeUnits);
        if (selectedGradeUnits.length > 0) {
          const asCustomOption = selectedGradeUnits[selectedGradeUnits.length - 1] as GradeUnit;
          if (asCustomOption.customOption) {
            debugger;
            createGradeUnit(asCustomOption);
          }
          else {
            setSelected(asCustomOption);
          }
        }
        else {
          setSelected(undefined);
        }
      }}
    />    
  </div>;
}