
import { Link, useParams } from "react-router-dom";
import FileBlob from "../../models/fileBlob";
import Tag from "../../models/tag";
import { useEffect, useState } from "react";
import { api } from "../../store/api";
import { TagsRoute } from "./tagsScreen";
import tagBreadcrumb from "../../components/tags/tagBreadcrumb";
import RecordDetailsCard from "../../components/records/recordDetailsCard";
import EntityTypes from "../../models/entityTypes";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import TagDetailsCard from "../../components/tags/tagDetailsCard";


export const TagExportRoute = "/settings/tags/:id/export";

interface TagExportRouteParams {
  id?: string;
}

export default function TagExportScreen() {
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<Tag>();
  const [error, setError] = useState<String>();
  const [downloadUri, setDownloadUri] = useState<FileBlob>();


  const params = useParams<keyof TagExportRouteParams>();
  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);

    api.settings.tags.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        document.title = `${record.label} - ${labels.tag.plural}`;
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.tag.singular + ` #${params.id}`);
      });
    
  }, [labels.tag.plural, labels.tag.singular, params.id]);

  useEffect(() => {
    if (record?.id) {
      api.settings.tags.export(record.id).then((uri) => {
        setDownloadUri(uri);
        setIsLoading(false);
      });
    }
    else {
      setDownloadUri(undefined);
    }
  }, [record]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">Export {labels.project.singular}</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">Settings</li>
          <li className="breadcrumb-item"><Link to={TagsRoute}>{labels.tag.plural}</Link></li>
          {tagBreadcrumb(record)}
          <li className="breadcrumb-item active">Export</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        {record && <TagDetailsCard record={record} showExport={false} isUserManaged={false} />}
      </div>
      <div className="col-md-8">
        <div className="card border-0 mb-4">
          <div className="card-body">
            {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Generating Export...</p>}
            {downloadUri?.url && <div className="d-flex flex-row justify-content-around">
              <a href={downloadUri.url} className="btn btn-lg btn-outline-success"><i className="fa-solid fa-download"></i> Download Export</a>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </div>);
}
