import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Link, generatePath, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AttributeValueEditor } from "../../components/attribute/attributeValueEditor";
import { Panel, PanelBody } from "../../components/panel/panel";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Project from "../../models/project";
import ProjectResource from "../../models/projectResource";
import { ProjectDetailsRoute } from "../projects/projectDetailsScreen";
import { ResourceDetailsRoute } from "../resources/resourceDetailsScreen";
import { fetchAttributes, selectConfig } from "../../store/configSlice";
import GradeTypes, { AllGrades } from "../../models/gradeTypes";
import GradeUnit from "../../models/gradeUnit";
import SelectGradeUnit from "../../components/project/selectGradeUnit";
import { setTitle } from "../../util/useDocumentTitle";

export const ProjectResourceEditRoute = "/projects/:projectId/resources/:id/edit";

interface ProjectResourceEditScreenParams {
  id?: number;
  projectId?: number;
}

export default function ProjectResourceEditScreen() {
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);
  const label = labels.projectResource;
  const entityApi = api.projectResources;

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState<ProjectResource>();
  const [error, setError] = useState<String>();
  const [grade, setGrade] = useState<GradeTypes>();
  const [gradeUnit, setGradeUnit] = useState<GradeUnit>();

  const params = useParams<keyof ProjectResourceEditScreenParams>();
  const [searchParams] = useSearchParams();


  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[EntityTypes.ProjectResource];

  const form = useForm<ProjectResource>();

  const { register, handleSubmit, reset, formState: { errors }, control, setValue } = form;
  const { fields, replace } = useFieldArray({
    name: "attributes",
    keyName: "attributeId",
    control
  });

  const onSubmit: SubmitHandler<Project> = data => {
    console.log(data);
    const request: Project = {
      ...data,
      partnerId: data.partner?.id ?? data.partnerId,
      attributes: data.attributes?.map((attrib) => {
        return {
          id: attrib.id,
          boolValue: attrib.boolValue,
          dateValue: attrib.dateValue,
          intValue: attrib.intValue,
          stringValue: attrib.stringValue,
          choicesValue: (attrib.choicesValue ?
            (Array.isArray(attrib.choicesValue) ? attrib.choicesValue : [attrib.choicesValue])
            : undefined
          ),
        };
      })
    };
    console.log(request);
    setIsLoading(true);
    (isEditing ? entityApi.update(request) : entityApi.create(request))
      .then((record) => {
        navigate(generatePath(ProjectDetailsRoute, { id: `${record.gradeUnit?.projectId}` }));
        setIsLoading(false);
      })
      .catch((reason) => {
        setError("Unable to load " + label.singular + ": " + reason);
        setIsLoading(false);
      });
  };

  // onLoad: set defaultValue with fetched record (if editing) or default
  useEffect(() => {
    dispatch(fetchAttributes(EntityTypes.ProjectResource)); // Load Attributes
    if (params.id) {
      setIsEditing(true);
      setIsLoading(true);
      entityApi.get(parseInt(params.id!)).then((record) => {
        setDefaultValue(record);
        setIsLoading(false);
      })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + label.singular + ` #${params.id}`);
        });
    }
    else {
      const projectId = searchParams.get("projectId");
      if (projectId) {
        api.projects.get(parseInt(projectId)).then((project) => {
          setDefaultValue({
            isActive: true,
            name: '',
            //projectId: project.id,
            project: project,
          });
        });
      }
      else {
        setDefaultValue({
          isActive: true,
          name: '',
        });
      }
    }
  }, [dispatch, entityApi, label.singular, params.id, searchParams]);

  // Initialize Form with Attributes and defaultValue
  useEffect(() => {
    if (attributes && defaultValue) {
      reset({
        ...defaultValue,
        attributes: attributes.map((attrib) => {
          if (defaultValue?.attributes) {
            const attributeValue = defaultValue?.attributes?.find((t) => t?.id === attrib.id);
            if (attributeValue) {
              // console.warn('replacing field', attributeValue);
              return attributeValue;
            }
          }
          return {
            id: attrib.id,
          }
        }),
      }, {

      });
      // console.warn('init form', defaultValue.gradeUnit);
      setGrade(defaultValue.gradeUnit?.grade);
      setGradeUnit(defaultValue.gradeUnit);
    }
  }, [attributes, replace, defaultValue, reset]);

  const watchAttributes = useWatch({
    control: control,
    name: `attributes`
  });

  const project = useWatch({
    control: control,
    name: 'project',
  });
  const resource = useWatch({
    control: control,
    name: 'resource',
  });

  const setGradeUnitId = (value: GradeUnit | undefined) => {
    console.error('updating GradeUnitId', value);
    setValue('gradeUnitId', value?.id);
    setGradeUnit(value);
  }


  // Title
  useEffect(() => {
    setTitle([
      `${isEditing ? 'Edit' : 'Create'} ${labels.projectResource.singular}`,
      project?.name,
      defaultValue?.name,
    ]);
  }, [defaultValue?.name, isEditing, labels.projectResource.singular, project?.name]);

  const watchIsAnchor = useWatch({
    control: control,
    name: 'isAnchor',
  });

  return (<div>
    <h1 className="page-header">{label.singular} Details</h1>
    <ul className="breadcrumb">
      <li className="breadcrumb-item"><Link to={generatePath(ProjectDetailsRoute, { id: `${project?.id}` })}>{project?.name}</Link></li>
      <li className="breadcrumb-item">{labels.projectResource.plural}</li>
      <li className="breadcrumb-item active">Edit</li>
    </ul>

    <div className="row">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Panel className="card border-0" isLoading={isLoading}>
            <PanelBody className="card-body">
              {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">{labels.resource.singular}</label>
                <div className="col-md-9">
                  <Link to={generatePath(ResourceDetailsRoute, { id: `${resource?.id}` })}>{resource?.name}</Link>
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">Project</label>
                <div className="col-md-9">
                  {project?.name}
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">Grade and Unit</label>
                <div className="col-md-2 col-4">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">Grade</span>
                    <select className={"form-control"}
                      value={grade}
                      onChange={(e) => {
                        setGrade(parseInt(e.currentTarget.value));
                        setGradeUnit(undefined);
                      }}
                    >
                      <option value=""></option>
                      {AllGrades.map((grade) => <option value={grade}>{labels.grades[grade]}</option>)}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">Unit</span>
                    {project && grade && <SelectGradeUnit project={project} grade={grade} onSelect={setGradeUnitId} selected={gradeUnit} />}
                  </div>
                  
                </div>
              </div>
              {/* <div className="col-md-3">
                <label className="form-label">Unit</label>
                
              </div> */}

              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">Is Anchor {label.singular}</label>
                <div className="col-md-9 pt-2">
                  <div className="form-check form-switch mb-2">
                    <input className="form-check-input" type="checkbox" id="isAnchor" {...register("isAnchor")} />
                    <label className="form-check-label" htmlFor="isAnchor">{Boolean(watchIsAnchor) ? 'Yes' : 'No'}</label>
                  </div>
                </div>
              </div>


              {fields?.map((field, index) => {
                const attribute = config.attributes[EntityTypes.ProjectResource]?.find(attrib => attrib.id === field.id);
                if (attribute) {
                  console.log('field', attribute.name, field);
                  return (<div key={field.id} className={"row mb-15px" + (attribute.isRequired ? " required" : "")}>
                    <label className="form-label col-form-label col-md-3">{attribute.name}</label>
                    <div className="col-md-9">
                      <AttributeValueEditor attribute={attribute} errors={errors} index={index} watch={watchAttributes} />
                    </div>
                  </div>
                  );
                }
                return <div key={field.id}></div>;
              }
              )}

              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">Status</label>
                <div className="col-md-9 pt-2">
                  <div className="form-check form-switch mb-2">
                    <input className="form-check-input" type="checkbox" id="isActive" {...register("isActive")} />
                    <label className="form-check-label" htmlFor="isActive">Active</label>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="offset-md-3 col-md-9 pt-2">
                  <button type="submit" className="btn btn-primary w-100px me-5px">Save</button>
                  <button type="button" onClick={() => navigate(-1)} className="btn btn-default w-100px">Cancel</button>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </form>
      </FormProvider>
    </div>
  </div>);
}
