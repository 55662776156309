import { Link, generatePath, useParams } from "react-router-dom";
import { SearchProps, api } from "../../store/api";
import { ReactNode, useEffect, useState } from "react";
import Tag, { tagOrAncestorIsUserManaged } from "../../models/tag";
import { TagsRoute } from "./tagsScreen";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import KnowledgeMapTypes, { AllKnowledgeMapTypes } from "../../models/knowledgeMapTypes";
import { TagEditRoute } from "./tagEditScreen";
import RecordsList, { RecordFilter } from "../../components/records/recordsList";
import EntityTypes from "../../models/entityTypes";
import { selectTags } from "../../store/configSlice";
import { RequiresPermission } from "../../components/role/requiresRole";
import { TagExportRoute } from "./tagExport";
import tagBreadcrumb from "../../components/tags/tagBreadcrumb";
import TagDetailsCard from "../../components/tags/tagDetailsCard";

export const TagDetailsRoute = "/settings/tags/:id";

interface TagDetailsRouteParams {
  id?: string;
}

export default function TagDetailsScreen() {
  const labels = useAppSelector(selectLabels);
  const allTags = useAppSelector(selectTags);
  const label = labels.tag;
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<Tag>();
  const [error, setError] = useState<String>();
  const params = useParams<keyof TagDetailsRouteParams>();
  const [subTagsFilter, setSubTagsFilter] = useState<RecordFilter<Tag>[]>([]);
  const [isUserManaged, setIsUserManaged] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'Tags';
  }, []);

  useEffect(() => {
    if (record) {
      setSubTagsFilter([
        { key: `sub-tags-${record?.id}`, label: `Sub-${label.plural} (Active)`, api: (search: SearchProps) => api.settings.tags.search({ ...search, parentTagId: record?.id }) },
        { key: `sub-tags-${record?.id}-all`, label: `Sub-${label.plural} (All)`, api: (search: SearchProps) => api.settings.tags.search({ ...search, parentTagId: record?.id, includeDisabled: true }) },
      ]);
    }
  }, [label.plural, record]);

  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);

    api.settings.tags.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        document.title = `${record.label} - ${labels.tag.plural}`;
        setIsLoading(false);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.tag.singular + ` #${params.id}`);
      });
  }, [labels.review.singular, labels.tag.plural, labels.tag.singular, params.id]);

  useEffect(() => {
    //tagOrAncestorIsUserManaged(tag)
    if (allTags && record) {
      setIsUserManaged(tagOrAncestorIsUserManaged(allTags, record));
    }
  }, [allTags, record]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">Settings</li>
          <li className="breadcrumb-item"><Link to={TagsRoute}>{labels.tag.plural}</Link></li>
          {tagBreadcrumb(record)}
        </ul>
      </div>
      <div className="ms-auto">
        <Link to={'create'} className="btn btn-success btn-rounded px-4 rounded-pill"><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Create Sub-{label?.singular}</Link>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        {record && <TagDetailsCard record={record} showExport={true} isUserManaged={isUserManaged} />}
      </div>
      <div className="col-md-8">
        {record && subTagsFilter.length && <RecordsList
          type={EntityTypes.Tag}
          //nameLabel={props.nameLabel}
          detailsRoute={TagDetailsRoute}
          //editRoute={props.editRoute}
          filters={subTagsFilter}
          //actions={props.actions}
          actions={
            (record: Tag) => <Link to={generatePath(TagEditRoute, { id: `${record.id}` })} className="btn btn-sm btn-outline-primary">Edit</Link>
          }
          showName={false}
          //sort={props.sort}
          //columns={props.columns}
          columns={[
            { label: 'Label', callback: (record) => <td key={'label'}><Link to={generatePath(TagDetailsRoute, { id: `${record.id}` })}>{record.label}</Link></td>, },
            { label: 'Type(s)', callback: (record) => <td key={'type'}>{AllKnowledgeMapTypes.filter((kmType) => (kmType & (record.knowledgeMapTypes ?? KnowledgeMapTypes.Undefined)) === kmType).map((kmType) => <div key={kmType}>{labels.knowledgeMapTypes[kmType]}</div>)}</td>, },
            {
              label: 'Management', callback: (record) => <td key={'management'}>
                {record.usersCanAddSubTags && <span title="Users can add sub-tags" className="badge bg-warning"><i className="fas fa-user-pen"></i></span>}
                {!record.usersCanAddSubTags && allTags && tagOrAncestorIsUserManaged(allTags, record) && <span title="Users can add sub-tags (via ancestor)" className="badge bg-secondary"><i className="fas fa-user-pen"></i></span>}
                {!record.isActive && <span className="badge bg-danger mx-1">DISABLED</span>}
              </td>,
            },
            //{ label: `Parent ${label.singular}`, callback: (record) => <td>{record.parentTag && <Link to={generatePath(TagDetailsRoute, { id: `${record.parentTagId}` })}>{record.parentTag?.label}</Link>}</td>, },
          ]}
        />}
      </div>
    </div>
  </div>);
}

